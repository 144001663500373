<mat-card class="create-request-card">
  <mat-card-title>Create a New Change Request</mat-card-title>
  <mat-card-header>130: Object Change Request</mat-card-header>
  <br>
  <mat-divider></mat-divider>

  <mat-card-content>
    <form>
      <div class="form-header">
        <div class="form-buttons">
          <button mat-raised-button color="primary" type="submit">Save Draft</button>
          <button mat-raised-button color="primary" type="button" (click)="nextStep()">Next</button>
        </div>
      </div>

      <div>
        <p>Create a New Change Request</p>
        <mat-form-field class="input-field" appearance="outline">
          <mat-label>Owner</mat-label>
          <mat-select required>
            <mat-option value="group1">Owner1</mat-option>
            <mat-option value="group2">Owner2</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field class="input-field" appearance="outline">
          <mat-label>Subject</mat-label>
          <input matInput placeholder="Enter subject">
        </mat-form-field>
      </div>

      <div>
        <mat-form-field class="input-field" appearance="outline">
          <mat-label>Due</mat-label>
          <input matInput [matDatepicker]="picker1">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
      </div>

      <mat-form-field class="input-field" appearance="outline">
        <mat-label>Describe the Issue</mat-label>
        <textarea matInput></textarea>
      </mat-form-field>

      <div class="form-group" appearance="outline">
        <label class="attachments-label">Attachments</label>
        <input type="file" multiple (change)="onFileSelected($event)" #fileInput>
        <button type="button" mat-raised-button color="primary" (click)="fileInput.click()">
          <mat-icon>attach_file</mat-icon>
          Add more files
        </button>
        <ul>
          @for (item of files; track $index) {
          <li>{{ item.name }}</li>
          }
        </ul>
      </div>

      <mat-form-field class="input-field" appearance="outline">
        <mat-label>Expires</mat-label>
        <input matInput [matDatepicker]="picker2">
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>

      <div class="form-row">
        <mat-form-field class="input-field" appearance="outline">
          <mat-label>Requestor</mat-label>
          <input matInput required placeholder="Enter requestor" value="Tim.Backeler.ext@bwi.de">
        </mat-form-field>
      </div>

      <mat-form-field class="input-field" appearance="outline">
        <mat-label>Device Name</mat-label>
        <mat-select>
          <mat-option value="device1">Device 1</mat-option>
          <mat-option value="device2">Device 2</mat-option>
          <mat-option value="device3">Device 3</mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </mat-card-content>

  <div style="margin: 50px;">
    <p>Request:</p>
  </div>

  <mat-card-content>
    <form class="object-form">
      <mat-card>
        <mat-card-header>
          <h1>Object Type:</h1>
        </mat-card-header>

        <mat-tab-group>
          <mat-tab label="NETWORK">
            <mat-card-content>
              <div class="form-row">
                <div class="form-column">
                  <h3>Action</h3>
                  <mat-radio-group class="action">
                    @for (item of actions; track $index) {
                    <mat-radio-button>{{ item }}</mat-radio-button>}
                  </mat-radio-group>
                </div>

                <div class="form-column">
                  <h3>Object Name</h3>
                  <mat-form-field appearance="outline">
                    <input matInput placeholder="Enter object name">
                  </mat-form-field>
                </div>

                <div class="form-column">
                  <h3>New Values</h3>
                  <mat-form-field appearance="outline">
                    <input matInput placeholder="Enter new values">
                  </mat-form-field>
                </div>

                <div class="form-column">
                  <h3>Scope</h3>
                  <mat-radio-group>
                    <div><mat-radio-button value="local">Local</mat-radio-button></div>
                    <div><mat-radio-button value="global">Global</mat-radio-button></div>
                  </mat-radio-group>
                </div>
              </div>

              <div class="form-row">
                <button mat-raised-button color="primary">+ Change More Objects</button>
              </div>

              <div class="form-row">
                <mat-form-field appearance="outline" class="input-field">
                  <mat-label>External change request id:</mat-label>
                  <input matInput>
                </mat-form-field>
              </div>

              <div class="form-row">
                <p>Workflow: Change-Object</p>
              </div>

              <div class="form-row">
                <p>From Template: 130: Object Change Request</p>
              </div>
            </mat-card-content>
          </mat-tab>

          <mat-tab label="SERVICE">
            <mat-card-content>
              <p>SERVICE</p>
            </mat-card-content>
          </mat-tab>
        </mat-tab-group>
      </mat-card>

      <div class="form-header">
        <button mat-raised-button color="primary" class="back-button" type="button" (click)="prevStep()">Back</button>
        <div class="form-buttons">
          <button mat-raised-button color="primary" type="submit">Save Draft</button>
          <button mat-raised-button color="primary" type="button">Next</button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
