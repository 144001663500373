import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {NetzObjektComponent} from "./netz-objekt.component";

const routes: Routes = [
  { path: '', component: NetzObjektComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NetzObjektRoutingModule { }
