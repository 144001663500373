import { Component } from '@angular/core';

@Component({
  selector: 'app-netz-objekt',
  templateUrl: './netz-objekt.component.html',
  styleUrl: './netz-objekt.component.scss'
})
export class NetzObjektComponent {
  currentStep = 1;
  files: File[] = [];

  actions: string[] = [
    'Add IPs to Network Object',
    'Remove IPs from Network Object',
    'New Network Object',
    'Delete Network Object'
  ];

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      for (let i = 0; i < input.files.length; i++) {
        this.files.push(input.files[i]);
      }

    }
  }

  nextStep() {
    if (this.currentStep == 1){
      this.currentStep++
    }
  }

  prevStep() {
    if (this.currentStep == 2){
      this.currentStep--
    }
  }
}
