import { NgModule } from '@angular/core';
import {SharedModule} from "../Shared/shared.module";
import {NetzObjektComponent} from "./netz-objekt.component";
import {NetzObjektRoutingModule} from "./netz-objekt-routing.module";


@NgModule({
  declarations: [
   NetzObjektComponent,
  ],
  imports: [
    NetzObjektRoutingModule,
    SharedModule,
  ]
})
export class  NetzObjektModule { }

